import { flattenOptions } from "@/entrypoints/shared/contest_utilities";
import { usePresentationStore } from "@/entrypoints/stores/presentation";
import { defineStore } from "pinia";
import { ref, computed } from "vue";
import type {
  HandRaiseOrchestrator,
  HandRaiseLiveResults,
  HandRaiseVote,
  HandRaiseResultsByVote,
  HandRaiseVoter,
  ContentPath,
} from "@/types";

export const useHandRaiseStore = defineStore("handRaise", () => {
  const presentationStore = usePresentationStore();

  // State
  const handRaiseOrchestrators = ref<HandRaiseOrchestrator[]>([]);

  // Getters
  const handRaiseLiveResults = computed<HandRaiseLiveResults>(() => {
    if (!presentationStore.activeHandRaiseOrchestrator) return null;

    const showPercentage = presentationStore.contestsInActiveVotingRound[0].resultPercentages;

    // First populate the object
    const partialResult: HandRaiseLiveResults = {
      not_voted: {
        showPercentage: showPercentage,
        reference: "not_voted",
        results: {
          count: 0,
        },
      },
    }

    if (presentationStore.contestsInActiveVotingRound[0].blankOption !== "disabled") {
      partialResult.blank = {
        reference: "blank",
        showPercentage: showPercentage && !presentationStore.contestsInActiveVotingRound[0].disregardBlankVotes,
        results: {
          count: 0,
          percentage: 0,
        },
      }
    }

    // We will only allow one contest per VR on handraise, so index 0 should do.
    flattenOptions(presentationStore.contestsInActiveVotingRound[0].options).forEach(option => {
      partialResult[option.reference] = {
        showPercentage: showPercentage,
        reference: option.reference,
        results: {
          count: 0,
          percentage: 0,
        },
      }
    });

    // Then calculate and update the counts
    presentationStore.activeHandRaiseOrchestrator?.handRaiseVotes?.forEach((vote: HandRaiseVote) => {
      if (!vote.optionReferences.length) partialResult.blank.results.count += vote.voterWeight;
      vote.optionReferences.forEach(optRef => partialResult[optRef].results.count += vote.voterWeight);
    });

    // Calculate absolute number of people that hasn't voted
    const result = handRaiseResultsByVoterName.value.reduce((voted, voter) => voted + (voter.voted ? 1 : 0), 0);
    partialResult.not_voted.results.count = presentationStore.voterCounts.totalVoters - result;

    // Calculate the percentage base
    const counts = Object.keys(partialResult).map(key => {
      switch (key) {
        case "not_voted": return 0;
        case "blank": return presentationStore.contestsInActiveVotingRound[0].disregardBlankVotes ? 0 : partialResult.blank.results.count;
        default: return partialResult[key].results.count;
      }
    });

    const percentage_base = counts.reduce((initial, count) => initial + count, 0);

    // Finally calculate and update the percentages
    function round(value: number, decimals: number): number {
      const rounder = Math.pow(10, decimals);
      return Number((Math.round(value * rounder) / rounder).toFixed(decimals));
    }

    function calculatePercentage(totalVotes: number, optionVotes: number): number {
      const percentage = ((100 / totalVotes) * optionVotes) || 0;
      return round(percentage, 1);
    }

    Object.keys(partialResult).forEach(key => {
      if (key !== "not_voted") partialResult[key].results.percentage =
        calculatePercentage(percentage_base, partialResult[key].results.count);
    });

    return partialResult;
  });

  const handRaiseResultsByVoterName = computed<HandRaiseVoter[]>(() => {
    const processedVoters = [];

    // Generate list of voters
    presentationStore.activeHandRaiseOrchestrator.voters.forEach((voter: HandRaiseVoter) => {
      processedVoters.push({
        ...voter,
        voted: false,
        optionReferences: null,
      });
    });

    // Update voter statuses
    presentationStore.activeHandRaiseOrchestrator.handRaiseVotes.forEach((vote: HandRaiseVote) => {
      processedVoters.map(voter => {
        if (vote.voterIdentifier === voter.identifier) {
          voter.voted = true;
          voter.optionReferences = vote.optionReferences;
          voter.updatedAt = vote.updatedAt;
        }
      });
    });

    return processedVoters;
  });

  const handRaiseResultsByVoterVote = computed<HandRaiseResultsByVote>(() => {
    const optionsHash: HandRaiseResultsByVote = {
      not_voted: {
        identifier: "not_voted",
        reference: "not_voted",
        votes: [],
      },
    }

    if (presentationStore.contestsInActiveVotingRound[0].blankOption !== "disabled") optionsHash.blank = {
      identifier: "blank",
      reference: "blank",
      votes: [],
    }

    flattenOptions(presentationStore.contestsInActiveVotingRound[0].options).forEach(
      (option) => optionsHash[option.reference] = {
        identifier: option.reference,
        reference: option.reference,
        votes: [],
      }
    );

    handRaiseResultsByVoterName.value.forEach(voter => {
      if (!voter.voted) {
        optionsHash.not_voted.votes.push(voter);
        return;
      }
      if (!voter.optionReferences.length) {
        optionsHash.blank.votes.push(voter);
        return;
      }
      voter.optionReferences.map(option => optionsHash[option].votes.push(voter));
    })

    return optionsHash;
  });

  // Actions
  const setHandRaiseOrchestrators = (payload: HandRaiseOrchestrator[]) =>
    handRaiseOrchestrators.value = payload;

  const addHandRaiseOrchestrator = (payload: HandRaiseOrchestrator) =>
    handRaiseOrchestrators.value.push(payload);

  const updateHandRaiseOrchestrator = (payload: HandRaiseOrchestrator, path: ContentPath) => {
    let storeItem = handRaiseOrchestrators.value.find((handRaiseOrchestrator: HandRaiseOrchestrator) =>
      handRaiseOrchestrator.id === path.id);
    storeItem = Object.assign(storeItem, payload);
  }

  const deleteHandRaiseOrchestrator = (path: ContentPath) => {
    const itemIndex = handRaiseOrchestrators.value.findIndex((handRaiseOrchestrator: HandRaiseOrchestrator) =>
      handRaiseOrchestrator.id === path.id);
    if (~itemIndex) handRaiseOrchestrators.value.splice(itemIndex, 1);
  }

  const addHandRaiseVote = (payload: HandRaiseVote, path: ContentPath) => {
    handRaiseOrchestrators.value.find((handRaiseOrchestrator: HandRaiseOrchestrator) =>
      handRaiseOrchestrator.id === path.hand_raise_orchestrator_id)?.handRaiseVotes?.push(payload);
  }

  const updateHandRaiseVote = (payload: HandRaiseVote, path: ContentPath) => {
    const parentItem = handRaiseOrchestrators.value.find((handRaiseOrchestrator: HandRaiseOrchestrator) =>
      handRaiseOrchestrator.id === path.hand_raise_orchestrator_id);
    let storeItem = parentItem.handRaiseVotes.find((vote: HandRaiseVote) => vote.id === path.id);
    storeItem = Object.assign(storeItem, payload);
  }

  const deleteHandRaiseVote = (path: ContentPath) => {
    const parentItem = handRaiseOrchestrators.value.find((handRaiseOrchestrator: HandRaiseOrchestrator) =>
      handRaiseOrchestrator.id === path.hand_raise_orchestrator_id);
    const itemIndex = parentItem.handRaiseVotes.findIndex((vote: HandRaiseVote) => vote.id === path.id);
    if (~itemIndex) parentItem.handRaiseVotes.splice(itemIndex, 1);
  }

  return {
    handRaiseOrchestrators,
    setHandRaiseOrchestrators,
    handRaiseLiveResults,
    handRaiseResultsByVoterName,
    handRaiseResultsByVoterVote,
    addHandRaiseOrchestrator,
    updateHandRaiseOrchestrator,
    deleteHandRaiseOrchestrator,
    addHandRaiseVote,
    updateHandRaiseVote,
    deleteHandRaiseVote,
  }
});
